/* Projects.css */
.projects {
  margin-top: 50px;
  text-align: center; /* Center text horizontally */
}

table {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;
}

th, td {
  border: 4px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Add responsive styles */
@media (max-width: 768px) {
  table {
    width: 100%;
  }
}
